.time-input {
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid var(--button-bg);
    width: calc(100% - 40px);
}

button {
    padding: 10px 20px;
    margin: 5px;
    border: none;
    border-radius: 5px;
    background-color: var(--button-bg);
    color: var(--text-color);
    cursor: pointer;
}

button:hover {
    background-color: var(--button-bg-hover);
}
