@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');

:root {
    --background-color: #fff;
    --text-color: #000;
    --button-bg: #f0f0f0;
    --input-bg: #fff;
    --input-text: #000;
    --gradient: linear-gradient(90deg, rgb(116, 151, 255) 0%, rgb(146, 240, 255) 50%, rgb(0, 17, 172) 100%);
}

html {
    zoom: 1.5;
    width: 100%; /* Ensure content fits within viewport width */
    height: 100%; /* Ensure content fits within viewport height */
    overflow: hidden; /* Prevent scroll bars */
}

body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: var(--background-color);
    color: var(--text-color);
}

body, html {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.button, button {
    background-color: var(--button-bg);
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    color: var(--text-color);
    cursor: pointer;
    display: inline-block;
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    line-height: 20px;
    list-style: none;
    margin: 0;
    outline: none;
    padding: 10px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: color 100ms;
    vertical-align: baseline;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.button:hover, button:hover,
.button:focus, button:focus {
    background-color: var(--button-bg);
}

input {
    padding: 7px;
    border-radius: 6px;
    font-size: 16px;
    background: var(--background-color);
    border: 2px solid transparent;
    color: var(--input-text);
    box-shadow: 0 0 0 1px #dddddd, 0 2px 4px 0 rgb(0 0 0 / 7%), 0 1px 1.5px 0 rgb(0 0 0 / 5%);
}

input:focus {
    border: 2px solid #000;
    border-radius: 4px; 
}

.theme-selector {
    position: fixed;
    top: 40px;
    left: 10px;
    padding: 10px;
    background: var(--background-color);
    border-radius: 10px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.settings-menu {
    position: fixed;
    top: 40px;
    left: 10px;
    padding: 20px;
    background: var(--input-bg);
    border-radius: 10px;
    border: 1px solid var(--button-bg);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    z-index: 10;
}

.settings-menu h3 {
    margin: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--button-bg);
}

.settings-menu h4 {
    margin: 10px 0;
}

.separator-button, .reset-button {
    margin: 5px 0;
    padding: 10px;
    background-color: var(--button-bg);
    color: var(--text-color);
    border: 1px solid var(--text-color);
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    text-align: center;
}

.separator-button.active, .theme-circle.active {
    border: 2px solid #000;
}

.theme-circle {
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 5px 5px 5px 0;
    border: 2px solid transparent;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    display: inline-block;
}

.theme-circle.active {
    transform: scale(1.1);
    border: 2px solid #000;
    box-shadow: 0 0 10px #000;
}

@keyframes gradient {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}

.header h1 {
    font-size: 2em; 
    background: var(--gradient);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: gradient 3s ease infinite;
    background-size: 200% 200%;
}

.container {
    text-align: center;
    width: 100%;
    max-width: 600px;
}

input[type="text"], button {
    padding: 10px;
    font-size: 16px;
    margin: 5px;
}

.russo-font {
    font-family: 'Russo One', sans-serif;
    font-size: 20px;
}

table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
}

th, td {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
}

th {
    background-color: #f2f2f2;
}
