table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    border: 1px solid var(--button-bg);
    padding: 10px;
    text-align: left;
}

th {
    background-color: var(--button-bg);
    color: var(--text-color);
}
