.settings-menu {
    position: fixed;
    top: 40px;
    left: 10px;
    padding: 20px;
    background: var(--input-bg);
    border-radius: 10px;
    border: 1px solid var(--button-bg);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    z-index: 10;
}

.settings-menu h3 {
    margin: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--button-bg);
}

.settings-menu h4 {
    margin: 10px 0;
}

.theme-circle {
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 5px 5px 5px 0;
    border: 2px solid transparent;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    display: inline-block;
}

.theme-circle.active {
    border: 2px solid #000;
}

.reset-button {
    margin: 5px 0;
    padding: 10px;
    background-color: var(--button-bg);
    color: var(--text-color);
    border: 1px solid var(--text-color);
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    text-align: center;
}
